<template>
  <v-form>
      <v-card :color="ant" class="fileupload ">
          <v-card-title class="card-header">
            Upload Files
            <v-spacer></v-spacer>
            <v-btn color="blue" large @click="show = true">Add Files</v-btn>
          </v-card-title>

          <v-card-text v-if="files.length !== 0">
            <fileList :files="files" />
          </v-card-text>
          <v-card flat height="80%" color='transparent' v-else>
            <div class="ma-5 filezone d-flex justify-center" @click="show = true">
                <v-icon large class="blue--text">
                  mdi-cloud-upload
                </v-icon>
                <p class="text-body-1 my-auto ml-5">
                  Upload one or multiple files here. Files <br />
                  containing personal information or any <br />
                  other banned files are prohibited.
                </p>
            </div>
            
          </v-card>
          
      </v-card>
    <v-row class="mt-5 pb-5 ml-2  sticky" >
      Estimated Price: ${{ formatAmount(getAmount) }}
      <v-spacer> </v-spacer>
      <v-btn text outlined color="" class="mr-5 px-10" @click="previous" >
        <v-icon>
          mdi-arrow-left
        </v-icon>
        Previous
      </v-btn>
      <v-btn depressed color="primary" class="mr-5 px-10" :loading='loading' @click="orderUpdate">
        Next
        <v-icon>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-row>
    <uploadModal :show="show" @close="show = false" />
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";
import generalMxn from "@/mixins/general_mixin";

export default {
  name: "FilesUpload",
  mixins: [colorMxn, validationMxn, generalMxn],
  components: {
    uploadModal: () => import("@/modules/Orders/components/uploadModal"),
    fileList: () => import("@/modules/Orders/components/fileList")
  },
  data() {
    return {
      show: false,
      upload: true,
      loading: false,
      files: [],
    };
  },
  computed: {
    ...mapGetters(['getUploadedFile', 'getAmount']),
  },
  watch: {
    getUploadedFile(val) {
      this.files = val;
    }
  },
  mounted() {
    this.files = this.getUploadedFile;
  },
  methods: {
    ...mapMutations(['setCurrentComponent', 'setOrderObject']),
    previous() {
      this.setCurrentComponent('OrderDetails')
    },
    async orderUpdate() {
      this.loading = true;
      const payload = {
        order_status: 'open',
      };
      const fullPayload = {
        params: payload,
        endpoint: `/orders/${this.$route.params.id}`,
      }
      const response = await this.performPutActions(fullPayload);
      this.loading = false;
      this.setOrderObject(null);
      if (response.status ) {
        this.$router.push('/dashboard/orders/open');
      }
      
    },
  }
};
</script>

<style scoped>
.filezone {
  border: #c0c4cc 0.1px dashed;
  height: 95%;
}

.filezone:hover {
  border: #459ef1 1px dashed;
}

.fileupload {
  height: 450px;
  overflow-y: scroll;
}

.fileupload::-webkit-scrollbar {
  display: none;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
</style>
